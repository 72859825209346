export * from './address.service';
import { AddressService } from './address.service';
export * from './anlagen.service';
import { AnlagenService } from './anlagen.service';
export * from './appConfig.service';
import { AppConfigService } from './appConfig.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './bookingChangeTracking.service';
import { BookingChangeTrackingService } from './bookingChangeTracking.service';
export * from './costCentre.service';
import { CostCentreService } from './costCentre.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './customerReport.service';
import { CustomerReportService } from './customerReport.service';
export * from './dossierDocument.service';
import { DossierDocumentService } from './dossierDocument.service';
export * from './dynamicBlock.service';
import { DynamicBlockService } from './dynamicBlock.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './formDefinition.service';
import { FormDefinitionService } from './formDefinition.service';
export * from './formInstance.service';
import { FormInstanceService } from './formInstance.service';
export * from './ghostWebhook.service';
import { GhostWebhookService } from './ghostWebhook.service';
export * from './home.service';
import { HomeService } from './home.service';
export * from './hours.service';
import { HoursService } from './hours.service';
export * from './info.service';
import { InfoService } from './info.service';
export * from './inventory.service';
import { InventoryService } from './inventory.service';
export * from './message.service';
import { MessageService } from './message.service';
export * from './myBusiness.service';
import { MyBusinessService } from './myBusiness.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './phoneNumber.service';
import { PhoneNumberService } from './phoneNumber.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './publicForms.service';
import { PublicFormsService } from './publicForms.service';
export * from './publicFormsDefinition.service';
import { PublicFormsDefinitionService } from './publicFormsDefinition.service';
export * from './purchaseOrder.service';
import { PurchaseOrderService } from './purchaseOrder.service';
export * from './pushNotification.service';
import { PushNotificationService } from './pushNotification.service';
export * from './salesOrder.service';
import { SalesOrderService } from './salesOrder.service';
export * from './storageLocation.service';
import { StorageLocationService } from './storageLocation.service';
export * from './supplier.service';
import { SupplierService } from './supplier.service';
export * from './timeBooking.service';
import { TimeBookingService } from './timeBooking.service';
export * from './timeBreak.service';
import { TimeBreakService } from './timeBreak.service';
export * from './timeBreakDefinition.service';
import { TimeBreakDefinitionService } from './timeBreakDefinition.service';
export * from './timeEntryType.service';
import { TimeEntryTypeService } from './timeEntryType.service';
export * from './todo.service';
import { TodoService } from './todo.service';
export * from './workingHours.service';
import { WorkingHoursService } from './workingHours.service';
export * from './workspace.service';
import { WorkspaceService } from './workspace.service';
export * from './zulip.service';
import { ZulipService } from './zulip.service';
export const APIS = [AddressService, AnlagenService, AppConfigService, AuthService, BookingChangeTrackingService, CostCentreService, CustomerService, CustomerReportService, DossierDocumentService, DynamicBlockService, EmployeeService, FormDefinitionService, FormInstanceService, GhostWebhookService, HomeService, HoursService, InfoService, InventoryService, MessageService, MyBusinessService, NewsService, PhoneNumberService, ProductService, ProjectService, PublicFormsService, PublicFormsDefinitionService, PurchaseOrderService, PushNotificationService, SalesOrderService, StorageLocationService, SupplierService, TimeBookingService, TimeBreakService, TimeBreakDefinitionService, TimeEntryTypeService, TodoService, WorkingHoursService, WorkspaceService, ZulipService];
